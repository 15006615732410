import React, { Component } from "react";
import { withRouter } from "react-router";
import firebase from "../../lib/firebase";

import LogInView from "./LogInView";

class LogInContainer extends Component {
    handleLogIn = async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
            await firebase
                .auth()
                .signInWithEmailAndPassword(email.value, password.value);
            this.props.history.push("/#/");
        } catch (error) {
            alert(error);
        }
    };

    render() {
        return <LogInView onSubmit={this.handleLogIn} />;
    }
}

export default withRouter(LogInContainer);