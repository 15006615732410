import React, { Component } from "react";
import { withRouter } from "react-router";
import firebase from "../../lib/firebase";
import RegistrationsView from "./RegistrationsView";
import _ from 'lodash'

class RegistrationsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registrations: []
        }
        this.loadRegistrations = this.loadRegistrations.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated && this.props.currentUser !== prevProps.currentUser) {
            this.loadRegistrations();
        }
    }

    componentWillMount() {
        this.loadRegistrations();
    }

    componentWillUnmount() {
        if (this.registrationsRefUnsub) this.registrationsRefUnsub();
    }

    loadRegistrations() {
        this.setState({ registrations: [] }, () => {
            let collection = this.props.authenticated && this.props.currentUser.email ? 'registrations-all' : 'registrations';
            this.db = firebase.firestore();
            this.registrationsRef = this.db.collection(collection);
            this.registrationsRefUnsub = this.registrationsRef.onSnapshot((snapshot) => {
                let newData = [];
                snapshot.forEach((doc) => {
                    newData.push(doc.data())
                });
                this.setState(prevState => {
                    newData.concat(prevState.registrations);
                    return {
                        registrations: _.uniq(_.map(newData, (r) => {
                            r.accept = r.accept ? 'Ja' : <div className={'alert m-0 p-0 px-1 alert-danger'}>NEIN</div>
                            if (r.stages && r.stages.length === 6) r.stages = "Alle Etappen"
                            else if (r.stages) r.stages = r.stages.reduce((result, item) => <>{result}<br />{item}</>)
                            return r;
                        }))
                    }
                });
            });
        });
    }

    render() {
        return <RegistrationsView authenticated={this.props.authenticated} user={this.props.currentUser} registrations={this.state.registrations} createRegistration={this.handleRegistration} />;
    }
}

export default withRouter(RegistrationsContainer);