import OssiloopLogo from '../../assets/logo.png'
import { useGlobal } from 'reactn';
import CookieConsent from "react-cookie-consent";
import React from 'react'

const Home = () => {

  const [cookieAccepted, setCookieAccepted] = useGlobal('cookieAccepted');

  return (

    <div className="container p-5">

      <div className={'alert alert-warning'}>
        Ohne Cookies, Google Analytics und Firebase funktioniert diese Seite nicht. Bitte bestätige unten auf der Seite, dass du mit dessen Verwendung einverstanden bist.
      </div>

    </div>

  )

}

export default Home