import 'react-app-polyfill/ie11';
import 'core-js/es6/number';


import React from 'react';
import ReactDOM from 'react-dom';

import { setGlobal } from 'reactn';

import App from './App';

setGlobal({
    cookieAccepted: getCookie('CookieConsent') === 'true' || false
});


ReactDOM.render(<App />, document.getElementById('ossiloop-app'));

document.getElementById('ossiloop-app').className = '';
document.getElementById('loader').className = 'se-pre-con loader hide';
setTimeout(function () {
    document.getElementById('loader').parentNode.removeChild(document.getElementById('loader'))
}, 550)

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}