import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import firebase from "./lib/firebase";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'react-table/react-table.css'

import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home'
import LogIn from './Components/LogIn/LogIn'
import About from './Components/About/About'
import Register from './Components/Register/Register'
import Registrations from './Components/Registrations/Registrations'
import CookiePage from './Components/CookiePage/CookiePage'

import ReactGA from 'react-ga';

import { useGlobal } from 'reactn';

import CookieConsent from "react-cookie-consent";

export default function App(props) {

    const [cookieAccepted, setCookieAccepted] = useGlobal('cookieAccepted');
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {

        if (cookieAccepted) {
            ReactGA.initialize('UA-134080042-1');
            ReactGA.pageview(window.location.pathname + window.location.search);
            login();
        }

    }, [cookieAccepted]);

    const login = () => {

        setLoading(true);

        try {
            firebase.auth().signInAnonymously();
        } catch (err) {
            console.log(err)
        }

        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                setAuthenticated(true);
                setCurrentUser(user);
                setLoading(false);
            } else {
                user = await firebase.auth().onAuthStateChanged();
                if (user) {
                    setAuthenticated(true);
                    setCurrentUser(user);
                    setLoading(false);
                } else {
                    setAuthenticated(false);
                    setCurrentUser(null);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <Router>
            <div>

                <Navbar cookieAccepted={cookieAccepted}></Navbar>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />

                {cookieAccepted && <>
                    <Route exact path="/login" component={LogIn} />
                    <Route exact path="/registrations" render={() => <Registrations authenticated={authenticated} currentUser={currentUser} />} />
                    <Route exact path="/register" render={() => <Register authenticated={authenticated} currentUser={currentUser} />} />
                </>}

                {!cookieAccepted && <>
                    <Route exact path="/login" component={CookiePage} />
                    <Route exact path="/registrations" component={CookiePage} />
                    <Route exact path="/register" component={CookiePage} />
                </>}

                <CookieConsent
                    location="bottom"
                    buttonText="Alles klar, einverstanden!"
                    onAccept={() => {
                        setCookieAccepted(true);
                    }}
                >
                    Ohne Cookies, Google Analytics und Firebase funktioniert diese Seite nicht. Bitte bestätige, dass du mit dessen Verwendung einverstanden bist.
                </CookieConsent>

            </div>
        </Router>
    );




}

