import ReactTable from "react-table"
import OssiloopLogo from '../../assets/logo.png'
import ReactToPrint from "react-to-print";
import React, { useEffect, useState, useRef } from 'react'
import '../../assets/print.css'
import moment from "moment";

const RegistrationsView = (props) => {

    if (!props.registrations) props.registrations = [];

    let [pageSize, setPageSize] = useState(0);

    const table = useRef(null);

    useEffect(() => {
        setPageSize(props.registrations.length)
    }, [props.registrations])

    const columns = [];

    columns.push({
        Header: "Name",
        accessor: "name"
    });

    columns.push({
        Header: "PLZ / Ort",
        accessor: "place"
    })

    if (props.authenticated && props.user && props.user.email) {

        columns.push({
            Header: "Handynummer",
            accessor: "phoneNumber"
        })

        columns.push({
            Header: "E-Mail",
            accessor: "mail"
        })

        columns.push({
            Header: "Einstieg",
            accessor: "entry"
        })

        columns.push({
            Header: "Etappen",
            accessor: "stages"
        })

        columns.push({
            Header: "Datum",
            accessor: "date"
        })

    }

    return (
        <div className='container'>
           
            <div ref={table}>
                <h3 className='mt-5'>Bisherige Anmeldungen</h3>
                <p>Folgende Läuferinnen und Läufer haben sich bisher angemeldet:</p>
                <ReactTable
                    data={props.registrations}
                    columns={columns}
                    className="-striped -highlight m-3 mb-5"
                    nextText={"Nächste Seite"}
                    previousText={"Vorherige Seite"}
                    pageText={"Seite"}
                    ofText={"von"}
                    rowsText={"Zeilen"}
                    noDataText={"Keine Anmeldungen vorhanden."}
                    showPagination={false}
                    pageSize={pageSize}
                />
                <p>Insgesamt {props.registrations.length} Anmeldungen {moment().format('DD.MM.YYYY')}</p>
            </div>

            {props.authenticated && props.user &&  props.user.email && <ReactToPrint
                trigger={() => <button className={'btn btn-secondary'}>Drucken</button>}
                content={() => table.current}
                bodyClass={'printBody'}
            />}

        </div>
    )

}

export default RegistrationsView