import React, { Component } from "react";
import { withRouter } from "react-router";
import firebase from "../../lib/firebase";
import RegisterView from "./RegisterView";
import moment from 'moment'

class RegisterContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registrations: []
        }
        this.handleRegistration = this.handleRegistration.bind(this);
    }

    async handleRegistration(formData) {
        try {
            await this.registrationsAllRef.add({...formData, date: moment().format('DD.MM.YYYY')})
            await this.registrationsRef.add({name: formData.name, place: formData.place, date: moment().format('DD.MM.YYYY')})
            return true;
        } catch(error) {
            console.log(error)
            return false;
        }
    }

    componentWillMount() {
        this.db = firebase.firestore();
        this.registrationsRef = this.db.collection('registrations');
        this.registrationsAllRef = this.db.collection('registrations-all');
    }

    render() {
        return <RegisterView authenticated={this.props.authenticated} user={this.props.currentUser} createRegistration={this.handleRegistration} />;
    }
}

export default withRouter(RegisterContainer);