import OssiloopLogo from '../../assets/logo.png'

import React from 'react'

const Home = () => {

    return (
        <div className='container mb-5'>

            <div className='m-3 mb-5'>
                <img src={OssiloopLogo} alt={'Ossiloop-Logo'} style={{ maxWidth: '100%', height: 'auto' }} />
            </div>

            <p>Es kann wieder losgehen! Der Ossiloop 2020 steht in den Startlöchern und will von uns bezwungen werden. Auf geht´s - der Ossiloop steht wieder an und doch so einige Lauffreunde aus dem Stadtgebiet Friesoythe und Bösel <span role="img" aria-label="Zwinkender Smiley">😉</span>, wollen sich unbedingt das Dörlooper Shirt erlaufen <span role="img" aria-label="Zwinkender Smiley">😉</span>.</p>

            <p>Da einige von uns schon mal am Lauf teilgenommen haben und von einem doch sehr großen logistischen Aufwand berichtet haben, hatten wir die Idee, mit einem Bus zu den jeweiligen Etappen zu fahren. Eingestiegen wird in Altenoythe (ZOB Schulstraße) und in Friesoythe (Hansaplatz).</p>
                
            <p>Das gemeinsame Anreisen hat für uns alle angenehmen Vorteile: direkter Ausstieg am Start und auch am Ziel steht unser Bus bereit und kann uns direkt aufnehmen. Wir können unsere Wertsachen im Bus lassen und fahren gemeinschaftlich!</p>
            
            <p>Geplant ist, dass wir von jeder Läuferin und jedem Läufer 50 € an Fahrtgeld einsammeln. Bitte habt das Geld zur ersten Busfahrt dabei! Damit kommt Ihr zum Ossiloop und werdet anschließend wieder an den Busstationen in Friesoythe und Altenoythe abgesetzt.</p>
            
            <p>Es besteht auch die Möglichkeit, zu einzeln Läufen mitzufahren. Hierfür müssen wir dann allerdings einen Obolus von 10 € pro Fahrt einsammeln.</p>
            
            <p>Derzeit wird für die Abfahrt ca. 17 Uhr angestrebt. Wir sollten dann gegen 22 Uhr wieder in Friesoythe sein.</p>
            
            <p>Sollte anschließend noch Geld überbleiben, werden wir die restlichen Summe <a href='http://jekieicha.de/' target="_blank" rel="noopener noreferrer">"jedem Kind eine Chance"</a> <span role="img" aria-label="Herz">❤</span> spenden.</p>
            
            <p>Nicht nur wir würden uns über einen regen Zuspruch freuen, sondern auch die Organisatoren des Ossiloop freuen sich über jeden Bus und finden unsere Idee richtig gut! Also steigt ein! <span role="img" aria-label="Bus">🚍</span></p>

            <p className={'m-2 mb-5'}><a className='btn btn-success btn-lg' href='/#/register'>Jetzt anmelden</a></p>
            
            <div className={'alert alert-warning mb-5'}><b>Wichtig:</b> Wir organisieren nur die Busfahrt, das Anmelden zum Ossiloop macht Ihr hier: <a href='https://www.spass-mit-sport.de/veranstaltungen/ossiloop-2020/anmelden/' target="_blank" rel="noopener noreferrer" className="alert-link">Ossiloop Anmeldung</a></div>

            <p><br></br></p>

        </div>
    )
    
}

export default Home