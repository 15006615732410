import React from "react";

const LogInView = ({ onSubmit }) => {
    return (
        <div className='container'>
            <h3 className='mt-5'>Login</h3>
            <form onSubmit={onSubmit} className='mt-5'>
                <div className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <input id="email" name="email" placeholder="E-Mail" type="text" required="required" className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Kennwort</label>
                    <input id="password" name="password" type="password" className="form-control" />
                </div>
                <div className="form-group">
                    <button name="submit" type="submit" className="btn btn-primary">Anmelden</button>
                </div>
            </form>

        </div>
    );
};

export default LogInView;