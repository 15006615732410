import OssiloopLogo from '../../assets/logo.png'

import React, { useState, useEffect } from 'react'
import _ from 'lodash'

const defaultForm = {
    name: '',
    street: '',
    place: '',
    phoneNumber: '',
    mail: '',
    stages: ["1. Etappe", "2. Etappe", "3. Etappe", "4. Etappe", "5. Etappe", "6. Etappe"],
    entry: '',
    accept: false,
    acceptOssiloop: false
}

const RegisterView = (props) => {

    let [formState, setForm] = useState(defaultForm);
    let [money, setMoney] = useState(50);
    let [savingState, setSavingState] = useState(false);
    let [error, setError] = useState(false);
    let [doNotDisplayRegistration, setDoNotDisplayRegistration] = useState(window.localStorage.getItem('doNotDisplayRegistration'));

    useEffect(() => {
        if (formState.stages.length === 6) {
            setMoney(50);
        } else {
            setMoney(10 * formState.stages.length);
        }
    }, [formState]);

    const handleFormUpdate = (event) => {
        let { name, value, type, checked } = event.target
        if (type === "checkbox") {
            if (Array.isArray(formState[name])) {
                let values = formState[name];
                if (checked) {
                    values.push(value);
                } else {
                    values = _.pull(values, value);
                }
                setForm({ ...formState, [name]: values })
            } else {
                setForm({ ...formState, [name]: checked })
            }
        } else {
            setForm({ ...formState, [name]: value })
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSavingState(true);
        let status = await props.createRegistration(formState)
        if (status) {
            window.localStorage.setItem('doNotDisplayRegistration', true)
        } else {
            setError("Unbekannter Fehler beim Speichern Ihrer Anmeldung.")
        }
        setSavingState(false);
        setDoNotDisplayRegistration(true);
    }

    return (
        <div className='container'>
            
            <h3 className='mt-5'>Anmeldung zur Busfahrt</h3>

            {!doNotDisplayRegistration && <div className={'mb-5'}>
                <p>Fülle bitte das folgende Formular aus, um an unserer Tour teilzunehmen <span role="img" aria-label="Lachender Smiley">🙂</span>!</p>
                <p>Zur Erinnerung: Kosten für die Busfahrt für alle Läufe: <b>50 €</b> oder für die Busfahrt für einzelne Läufe: <b>10,00 € / Fahrt</b>. Das Geld wird zur ersten Busfahrt eingesammelt.</p>
                <div className='m-3 mt-5'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label className="col-4 col-form-label" htmlFor="name">Name</label>
                            <div className="col-8">
                                <input id="name" value={formState.name} onChange={handleFormUpdate} name="name" placeholder="Max Mustermann" type="text" required="required" className="form-control" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="place" className="col-4 col-form-label">PLZ Ort</label>
                            <div className="col-8">
                                <input id="place" value={formState.place} onChange={handleFormUpdate} name="place" placeholder="26169 Altenoythe" type="text" required="required" className="form-control" />
                            </div>
                        </div>


                        <div className="form-group row">
                            <label htmlFor="phoneNumber" className="col-4 col-form-label">Handy-Nummer</label>
                            <div className="col-8">
                                <input id="phoneNumber" value={formState.phoneNumber} onChange={handleFormUpdate} name="phoneNumber" placeholder="0151/1234567" type="text" required="required" className="form-control" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="mail" className="col-4 col-form-label">E-Mail</label>
                            <div className="col-8">
                                <input id="mail" value={formState.mail} onChange={handleFormUpdate} name="mail" placeholder="max@web.de" type="text" required="required" className="form-control" />
                            </div>
                        </div>


                        <div>
                            <div className="form-group row">
                                <label className="col-4">Einstieg</label>
                                <div className="col-8">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input name="entry" id="entry_0" type="radio" className="custom-control-input" defaultValue="ZOB Altenoythe" required="required" onChange={handleFormUpdate} />
                                        <label htmlFor="entry_0" className="custom-control-label">ZOB Altenoythe (Schulstraße)</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input name="entry" id="entry_1" type="radio" className="custom-control-input" defaultValue="Hansaplatz" required="required" onChange={handleFormUpdate} />
                                        <label htmlFor="entry_1" className="custom-control-label">Hansaplatz Friesoythe</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-form-label">Läufe</label>
                                <div className="col-8">
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage1" type="checkbox" defaultChecked={formState.stages.includes('1. Etappe')} className="custom-control-input" defaultValue={'1. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage1" className="custom-control-label">1. Etappe (11,2) - Bennsersiel - Dunum - Dienstag, 28.04.20</label>
                                        </div>
                                    </div>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage2" type="checkbox" defaultChecked={formState.stages.includes('2. Etappe')} className="custom-control-input" defaultValue={'2. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage2" className="custom-control-label">2. Etappe (11,5) - Dunum - Plaggenburg - Freitag, 01.05.20</label>
                                        </div>
                                    </div>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage3" type="checkbox" defaultChecked={formState.stages.includes('3. Etappe')} className="custom-control-input" defaultValue={'3. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage3" className="custom-control-label">3. Etappe (9,7) - Aurich - Aurich - Dienstag, 05.05.20</label>
                                        </div>
                                    </div>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage4" type="checkbox" defaultChecked={formState.stages.includes('4. Etappe')} className="custom-control-input" defaultValue={'4. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage4" className="custom-control-label">4. Etappe (11,9) - Holtrop - Bagband - Freitag, 08.05.20</label>
                                        </div>
                                    </div>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage5" type="checkbox" defaultChecked={formState.stages.includes('5. Etappe')} className="custom-control-input" defaultValue={'5. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage5" className="custom-control-label">5. Etappe (9,8) - Gut Stiekelkamp - Holtland - Dienstag 12.05.20</label>
                                        </div>
                                    </div>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input name="stages" id="stage6" type="checkbox" defaultChecked={formState.stages.includes('6. Etappe')} className="custom-control-input" defaultValue={'6. Etappe'} onChange={handleFormUpdate} />
                                            <label htmlFor="stage6" className="custom-control-label">6. Etappe (10,2) - Nortmoor - Leer - Freitag, 15.05.20</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-4 d-none d-sm-block" />
                            <div className="col-sm-12 col-md-8">
                                <p>Kosten: <b>{money} €</b></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-4 d-none d-sm-block" />
                            <div className="col-sm-12 col-md-8">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input name="accept" onChange={handleFormUpdate} id="accept" type="checkbox" className="custom-control-input" required="required" defaultValue={formState.accept} />
                                    <label htmlFor="accept" className="custom-control-label">Ich habe verstanden, dass die Anmeldung verbindlich ist. Die Fahrtkosten sind in jedem Fall zu bezahlen!</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-4 d-none d-sm-block" />
                            <div className="col-sm-12 col-md-8">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input name="acceptOssiloop" onChange={handleFormUpdate} id="acceptOssiloop" type="checkbox" className="custom-control-input" required="required" defaultValue={formState.acceptOssiloop} />
                                    <label htmlFor="acceptOssiloop" className="custom-control-label">Ich habe verstanden, dass dies nur die Anmeldung für die Busfahrt ist. Die Anmeldung für den Ossiloop muss ich selber machen.</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="offset-4 col-8">
                                <button name="submit" className="btn btn-success btn-lg" disabled={savingState}>
                                    {savingState && <span>Bitte warten...</span>}
                                    {!savingState && <span>Anmelden</span>}
                                </button>
                            </div>
                        </div>

                    </form>

                    {error && <div className="alert alert-danger" role="alert">
                        {error}
                    </div>}


                </div>
            </div>}

            {doNotDisplayRegistration && <div className="alert alert-primary" role="alert">
                Vielen Dank für Deine Anmeldung!            
            </div>}

            <div className="alert alert-info" role="alert">
                <b>Wichtig!</b> Wenn es wider Erwarten zu wenig Anmeldungen gibt, finden die Busfahren nicht statt. Gebühren werden dann selbstverständlich zurückerstattet.
            </div>

            <div className="alert alert-info" role="alert"><b>Nachträgliche Änderungen</b> können direkt mit Andreas Emken (<span role="img" aria-label="E-Mail">📬</span> <a href='mailto:andreas@emken.net'>andreas@emken.net</a>, <span role="img" aria-label="Handy">📱</span> 015119521661) abgesprochen werden.</div>

            <p className='mb-5'><b>Thema Datenschutz:</b> Eure Daten werden nur während der Dauer des Ossiloop gespeichert. Wir werden die Daten anschließend löschen und die Seite wird nicht mehr verfügbar sein. Es werden Ausdrucke für Passagierlisten erstellt und vermutlich werden wir eine WhatsApp Gruppe gründen, um die Organisation zu erleichtern. Weiterhin sichern wir Dir zu, dass wir deine Daten auf keinen Fall weitergeben.</p>

        </div>
    )

}

export default RegisterView