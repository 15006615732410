import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';


let config = {
    apiKey: "AIzaSyCjaNUcYxxi9d0q7td0Ea-5ohCqbK1RUOE",
    authDomain: "ossiloop-2019-emken.firebaseapp.com",
    databaseURL: "https://ossiloop-2019-emken.firebaseio.com",
    projectId: "ossiloop-2019-emken",
    storageBucket: "ossiloop-2019-emken.appspot.com",
    messagingSenderId: "1089983237170"
};
let base  = firebase.initializeApp(config);
export default base