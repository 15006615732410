import React from 'react'

const Navbar = (props) => {

    return <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/#">Ossiloop 2020 - Bustour</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        {props.cookieAccepted && <a className="btn btn-success" href="/#/register">Jetzt anmelden</a>}
                        {!props.cookieAccepted && <a className="nav-link" href="/#/register">Jetzt anmelden</a>}
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/#/registrations">Bisherige Anmeldungen</a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/#/about">Impressum / Datenschutz</a>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/#/login">Admin</a>
                    </li>
                </ul>
            </div>
        </nav>

    </div>

}

export default Navbar;